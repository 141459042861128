import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-videotutorials',
  templateUrl: './videotutorials.component.html',
  styleUrls: ['./videotutorials.component.scss']
})
export class VideotutorialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
