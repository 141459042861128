<!-- <div class="container">
    <div class="row mt-5">
        <div class="col-md-6 text-center">
            <h2>Bienvenido a la sección de tutoriales</h2>
            <h3>¿Qué deseas aprender?</h3>
        </div>
    </div>
</div> -->

<mdb-card
    style="background-image: url('assets/media/images/2.png');     background-position: center; background-size : cover"
    class="my-5 ">
    <div class="   py-5 px-4 mask rgba-white-light ">
        <div my-5>
            <br>
            <h2 class="card-title h1-responsive pt-3 mb-5 font-bold pl-3"><strong>Bienvenido a la sección de
                    tutoriales</strong></h2>

            <h4 class="pl-3"> <strong>¿Qué deseas aprender?</strong></h4>

        </div>
    </div>
</mdb-card>

<div class="container">
    <div class="row my-5">
        <div class="col-md-6 view overlay zoom my-md-3 my-5">
            <img src="assets/media/images/tutorial.png" class="img-fluid zoom" alt="Responsive image">
            <a routerLink="/tutorial">
                <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
        </div>
        <div class="col-md-6 view overlay zoom my-md-3 my-5">
            <img src="assets/media/images/dictamen.png" class="img-fluid zoom" alt="Responsive image">
            <a routerLink="/tutorialdictamen">
                <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
        </div>
        <div class="col-md-6 view overlay zoom my-md-3 my-5">
            <img src="assets/media/images/videos.jpg" class="img-fluid zoom" alt="Responsive image">
            <a routerLink="/videotutoriales">
                <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
        </div>
        <div class="col-md-6 view overlay zoom my-md-3 my-5">
            <img src="assets/media/images/num.png" class="img-fluid zoom" alt="Responsive image">
            <a routerLink="/tutorialnumeroficial">
                <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
        </div>


    </div>






    <hr>

    <div class="row my-4">
        <div class="col-md-6" id="mapa">
            <h4>
                ¿Listo? Dirigete al mapa dando clic en el siguiente botón.
            </h4>
        </div>
        <div class="col-md-6">
            <a href="https://tepatitlan.visorurbano.com/mapa" mdbBtn type="button" size='lg' color="dark-green"
                rounded="true" mdbWavesEffect>Ir
                al Mapa</a>
        </div>
    </div>

</div>