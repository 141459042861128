<div class="row pt-5">
    <div class="row d-flex justify-content-center">
        <div class="col-md-8">
            <img src="assets/media/images/youtube.png" alt="" class="img-fluid z-depth-0">
        </div>

    </div>

</div>












<div class="container ">


    <div class=" row justify-content-center">
        <div class="col-md-9">
            <h3></h3>
            <h5 class="text-justify"><span class="ml-2"></span> En VisorUrbano te apoyamos para que conozcas mejor como
                utilizar la plataforma, por
                ello, hemos preparado una serie de videotutoriales especialmente para ti.</h5>
        </div>
    </div>
    <hr class="my-5">
    <div class="row justify-content-center">
        <div class="col-md-9">
            <h5 class="font-weight-bold ">
                <mdb-icon class="px-2 red-text" size="2x" fab icon="youtube"></mdb-icon> Módulos dentro de VisorUrbano y
                Ortofoto de Tepatitlán
            </h5>
            <div>
                <div class="contenedor">
                    <iframe class="responsive-iframe" src="https://www.youtube.com/embed/7MD38pvV7Xw"></iframe>
                </div>
            </div>
        </div>
    </div>
    <hr class="my-5">
    <div class="row justify-content-center">
        <div class="col-md-9">
            <h5 class="font-weight-bold">
                <mdb-icon class="px-2 red-text" size="2x" fab icon="youtube"></mdb-icon>Constancia de No Adeudo Predial
                completamente GRATUITO
            </h5>
            <div>
                <div class="contenedor">
                    <iframe class="responsive-iframe" src="https://www.youtube.com/embed/1-Gj0cvRPBo"></iframe>
                </div>
            </div>
        </div>
    </div>
    <hr class="my-5">
    <div class="row justify-content-center">
        <div class="col-md-9">
            <h5 class="font-weight-bold">
                <mdb-icon class="px-2 red-text" size="2x" fab icon="youtube"></mdb-icon>Trámite de Número Oficial
                completamente GRATUITO
            </h5>
            <div>
                <div class="contenedor">
                    <iframe class="responsive-iframe" src="https://www.youtube.com/embed/_xSwIgBZbjI"></iframe>
                </div>
            </div>
        </div>
    </div>
    <hr class="my-5">
    <div class="row justify-content-center">
        <div class="col-md-9">
            <h5 class="font-weight-bold">
                <mdb-icon class="px-2 red-text" size="2x" fab icon="youtube"></mdb-icon>Trámite del Dictamen de Uso de
                suelo para Giros blancos completamente GRATUITO
            </h5>
            <div>
                <div class="contenedor">
                    <iframe class="responsive-iframe" src="https://www.youtube.com/embed/wi_bVqeBpgY"></iframe>
                </div>
            </div>
        </div>
    </div>



    <hr class="my-4">
    <div class="row my-4">
        <div class="col-md-6" id="mapa">
            <h4>
                ¿Listo? Dirigete al mapa dando "clic" en el siguiente botón.
            </h4>
        </div>
        <div class="col-md-6">
            <a href="https://tepatitlan.visorurbano.com/mapa" mdbBtn type="button" size='lg' color="dark-green"
                rounded="true" mdbWavesEffect>Ir
                al Mapa</a>
        </div>
    </div>

</div>