<div class="container mt-5">
    <div class="row py-5 justify-content-center">
        <div class="col-md-7 my-auto order-md-last">
            <h2 class="text-center py-3">ERROR 404 </h2>
            <h4 class="text-justify">Lo sentimos, no encontramos lo que estabas buscando, te invitamos a ir al inicio o
                contactarnos.</h4>
        </div>
        <div class="col-md-4 py-5 ">
            <img src="assets/media/images/err.jpg" alt="" class="img-fluid z-depth-0 py-5">
        </div>

    </div>
</div>