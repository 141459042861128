import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http'
import Swal from 'sweetalert2'
@Component({
  selector: 'app-contactnoficiales',
  templateUrl: './contactnoficiales.component.html',
  styleUrls: ['./contactnoficiales.component.scss']
})
export class ContactnoficialesComponent implements OnInit {
  grupocontacto: FormGroup
  constructor(public fb: FormBuilder, public http: HttpClient) { }

  ngOnInit(): void {

    this.grupocontacto = this.fb.group(
      {
        name: ['', Validators.required],
        email: ['', Validators.compose([,
          Validators.required,
          Validators.email
        ])],
        address: ['', Validators.required],
        clave: ['', Validators.required]
      }
    )
  }


  send() {

    let correo: any = {
      name: this.grupocontacto.value.name,
      email: this.grupocontacto.value.email,
      address: this.grupocontacto.value.address,
      clave: this.grupocontacto.value.clave

    }
    this.http.post('https://tepatitlan.visorurbano.com/mail/cnoficial/', correo).subscribe((res) => {
      console.log(res);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Solicitud enviada con éxito',
        showConfirmButton: false,
        timer: 1500
      })
      this.grupocontacto.reset();
    })


  }

}
