<div class="row text-center">
    <div class="col-md-6 my-auto">
        <h4>¿Tienes problemas con tu Número Oficial?</h4>

        <mdb-icon far icon="file-pdf" class="text-center text-success my-2" size="3x"></mdb-icon>
        <p class="text-justify">Llena el formulario para solicitar la revisión de tu número, en la brevedad recibirás un correo de un
            funcionario público referente a tu solicitud. </p>
        <p class="text-justify">Recuerda que el tramite de número oficial dentro de VisorUrbano es completamente legal y gratuito.</p>
    </div>
    <div class="col-md-6">
        <form class="text-center  p-md-5" [formGroup]="grupocontacto">
            <p class="h4 mb-4" style="color: #235aa5;">Resolver problema de Número Oficial</p>
            <input type="text" id="defaultContactFormName" class="form-control mb-4" placeholder="Nombre"
                formControlName="name">
            <input type="email" id="defaultContactFormEmail" class="form-control mb-4" placeholder="Correo Electrónico"
                formControlName="email">
            <input type="email" id="defaultContactFormEmail" class="form-control mb-4" placeholder="Domicilio"
                formControlName="address">
            <input type="email" id="defaultContactFormEmail" class="form-control mb-4" placeholder="Clave Catastral"
                formControlName="clave">
            <label for="" class="text-center" style="color:gray"> *Puedes obtenerla desde el mapa de VisorUrbano</label>
            <div class="row">

            </div>
            <button mdbBtn color="success" type="submit" [disabled]="grupocontacto.invalid"  (click)="send()">Enviar</button>
        </form>
    </div>
</div>