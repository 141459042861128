import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noficialmain',
  templateUrl: './noficialmain.component.html',
  styleUrls: ['./noficialmain.component.scss']
})
export class NoficialmainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
