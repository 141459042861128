import { Component, HostListener, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(private toat: ToastrService) { }






  ngOnInit(): void {
    window.scroll(0,0);
    this.toat.info("Ahora VisorUrbano Tepatitlán genera dictámenes, ve al tutorial. ","¡Atención!");
    this.toat.info("VisorUrbano muestra la información proporcionada por las jefaturas de ASTEPA, Padrón y Licencias, Catastro y Ordenamiento Territorial (Planeación y Desarrollo Urbano).  Cualquier error en los datos es responsabilidad directa de la jefatura que proporcionó la información. ","¡Atención!");


  }

}