import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0,0);

    $(window).scroll(function () {
      var scroll = $(window).scrollTop()+850;
      var message = $("#mapa");
      var scrollmessage = message.offset().top ;
     
      if (scroll >= scrollmessage) {
       $("#mapa").addClass("animated bounceInLeft");
      }
      
    });
  }

}
