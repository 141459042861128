import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
    // $(window).scroll(function () {
    //   var scroll = $(window).scrollTop()+850;
    //   var message = $("#mapa");
    //   var scrollmessage = message.offset().top ;
    //   if (scroll >= scrollmessage) {
    //    $("#mapa").addClass("animated jello");
    //   }
    // });
  }



}
