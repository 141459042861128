
<div class="container">

    <div class="row pt-5">
        <div class="row pt-5">
            <div class=" row pt-5">
            </div>
        </div>
    </div>
    
    <app-noficialtutorial></app-noficialtutorial>
    
    <hr class="my-4">
    <div class="row my-4">
        <div class="col-md-6" id="mapa">
            <h4>
                ¿Listo? Dirigete al mapa dando "clic" en el siguiente botón.
            </h4>
        </div>
        <div class="col-md-6">
            <a href="https://tepatitlan.visorurbano.com/mapa" mdbBtn type="button" size='lg' color="dark-green"
                rounded="true" mdbWavesEffect>Ir
                al Mapa</a>
        </div>
    </div>

</div>





