import { Component, OnInit, ViewChild } from '@angular/core';
import { ChildActivationStart } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import { modalConfigDefaults } from 'angular-bootstrap-md/lib/free/modals/modal.options';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
 
  }

}
