<p class="text-center h1 py-5" style="color: #235aa6;">
    TUTORIAL ASIGNACIÓN DE NÚMERO OFICIAL
</p>
<div class="container">
    <div class="row">
        <div class="col-md-6  my-3  col-example">
            <div class="row h-100">
                <!-- Grid column -->
                <div class=" card-group mt-2 column-2 px-2 mb-r">
                    <!--Card-->
                    <div class="card" style="background-color: white;">
                        <!--Card image-->
                        <div class="view">
                            <img src="/assets/media/images/2.png" class="card-img-top" alt="photo">
                            <a href="#">
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                        <!--Card content-->
                        <div class="card-body ">
                            <!--Title-->
                            <h4 class="card-title text-center " style="color: #235aa6;">1- ACCEDER AL MAPA</h4>
                            <!--Text-->
                            <p class="card-text text-justify">En la vista principal de <a href="tepatitlan.visorurbano.com">tepatitlan.visorurbano.com</a> se
                                encuentra un
                                botón con el texto "Ir al mapa" , debes de dar "clic" en el mismo. En la vista general
                                del mapa,
                                encontrarás capas cargadas por default y las herramientas disponibles dentro de Visor.
                            </p>
                        </div>
                    </div>
                    <!--/.Card-->
                </div>
                <!-- Grid column -->
            </div>
            <!-- Grid row -->
        </div>
        <div class="col-md-6  w-100  my-3 col-example">
            <div class="row">
                <!-- Grid column -->
                <div class="column-2 px-2 mb-r">
                    <!--Card-->
                    <div class="card " style="background-color: white;">
                        <!--Card image-->
                        <div class="view">
                            <img src="/assets/media/images/5.png" class="card-img-top" alt="photo">
                            <a href="#">
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                        <!--Card content-->
                        <div class="card-body ">
                            <!--Title-->
                            <h4 class="card-title text-center" style="color: #235aa6;">2- UBICA TU PREDIO / DESPLEGAR
                                LOS DETALLES DEL PREDIO
                            </h4>
                            <!--Text-->
                            <p class="card-text text-justify">Para desplegar los detalles de cualquier predio es
                                necesario
                                dar "clic" sobre el predio, el sistema automáticamente
                                desplegará un recuadro que contiene un poco de información del predio como la clave
                                catastral del mismo, dentro del recuadro se encuentra un botón
                                que dice "Ver detalle", al hacer "clic" el sistema desplegará más información sobre el
                                predio.</p>
                        </div>
                    </div>
                    <!--/.Card-->
                </div>
                <!-- Grid column -->
            </div>
            <!-- Grid row -->
        </div>
    </div>
    <div class="row">
        <div class="col-md-6  my-3  col-example">
            <div class="row">
                <!-- Grid column -->
                <div class=" column-2 px-2 mb-r">
                    <!--Card-->
                    <div class="card " style="background-color: white;">
                        <!--Card image-->
                        <div class="view">
                            <img src="/assets/media/images/asignacion.png" class="card-img-top" alt="photo">
                            <a href="#">
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                        <!--Card content-->
                        <div class="card-body">
                            <!--Title-->
                            <h4 class="card-title text-center" style="color: #235aa6;">3- BOTÓN OBTEN TU ASIGNACIÓN DE
                                NÚMERO OFICIAL</h4>
                            <!--Text-->
                            <p class="card-text text-justify">Al dar "clic" en el botón asignación de número de oficial,
                                te enviara a una nueva página donde podrás encontrar un pdf con tu número oficial.</p>
                        </div>
                    </div>
                    <!--/.Card-->
                </div>
                <!-- Grid column -->
            </div>
            <!-- Grid row -->
        </div>
        <div class="col-md-6  my-3  col-example">
            <div class="row h-100">
                <!-- Grid column -->
                <div class="card-group mt-2 column-2  px-2 mb-r">
                    <!--Card-->
                    <div class="card " style="background-color: white; ">
                        <!--Card image-->
                        <div class="view">
                            <img src="/assets/media/images/nooficial.png" class="card-img-top" alt="photo">
                            <a href="#">
                                <div class="mask rgba-white-slight"></div>
                            </a>
                        </div>
                        <!--Card content-->
                        <div class="card-body ">
                            <!--Title-->
                            <h4 class="card-title text-center" style="color: #235aa6;">!FELICIDADES!</h4>
                            <!--Text-->
                            <p class="card-text text-justify">Ahora tienes tu asignación de número oficial.</p>
                        </div>
                    </div>
                    <!--/.Card-->
                </div>
                <!-- Grid column -->
            </div>
            <!-- Grid row -->
        </div>
    </div>
</div>