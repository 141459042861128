import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModule, ModalModule } from 'angular-bootstrap-md';
import { HeaderComponent } from './components/header/header.component';
import { AboutComponent } from './components/about/about.component';
import { IndexComponent } from './components/index/index.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { FooterComponent } from './components/footer/footer.component';
import { DictamentutorialComponent } from './components/dictamentutorial/dictamentutorial.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MaintotutorialsComponent } from './components/maintotutorials/maintotutorials.component';
import { ErrComponent } from './components/err/err.component';
import { VideotutorialsComponent } from './components/videotutorials/videotutorials.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ContactnoficialesComponent } from './components/contactnoficiales/contactnoficiales.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NoficialtutorialComponent } from './components/noficialtutorial/noficialtutorial.component';
import { NoficialmainComponent } from './components/noficialmain/noficialmain.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AboutComponent,
    IndexComponent,
    TutorialComponent,
    FooterComponent,
    DictamentutorialComponent,
    MaintotutorialsComponent,
    ErrComponent,
    VideotutorialsComponent,
    NavbarComponent,
    ContactnoficialesComponent,
    NoficialtutorialComponent,
    NoficialmainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(), 
    ModalModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
