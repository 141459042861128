<div class="container pt-5">
    <div class="row pt-5">
        <div class=" d-none d-md-block">
            <br><br><br><br><br>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-8">
                <img src="assets/media/images/tutorial.jpg" alt="" class="img-fluid z-depth-0">
            </div>

        </div>

    </div>
    <div class="row">
        <div class="col-md-12">
            <h3>TUTORIAL</h3>
            <p class="grey-text">Bienvenido al Tutorial de Visor Urbano, en este apartado te enseñaremos a utilizar las
                herramientas que están disponibles para tu uso.</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/1.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="mt-4">
                        <h5>1- ACCEDER AL MAPA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify"> En la vista principal de tepatitlan.visorurbano.com se
                        encuentra un botón con el
                        texto <a href="https://tepatitlan.visorurbano.com/mapa/">
                            "Ir al mapa"
                        </a>, debes de dar "clic" en el mismo.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>




        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/2.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3">
                        <h5>2- VISTA GENERAL DEL MAPA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify"> En la vista general del mapa, encontrarás capas cargadas por
                        default y las
                        herramientas disponibles dentro de Visor.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>


        <div class="col-md-4 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/3.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-4">
                        <h5>3- MENÚ DE CAPAS</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify"> Podrás acceder al menú de las capas dando "clic" en el
                        siguiente ícono <mdb-icon fas icon="bars"></mdb-icon>, una vez hecho esto, Visor te mostrará los
                        grupos de capas
                        categorizadas.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>



        <div class="col-md-4 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/4.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3">
                        <h5>4- DESPLEGAR GRUPO Y ACTIVAR CAPAS</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify"> Para desplegar un grupo de capas, es necesario dar "clic" en el
                        siguiente ícono
                        <mdb-icon fas icon="angle-down"></mdb-icon>, una vez hecho esto, observarás el listado de capas
                        disponibles del grupo, para encenderlas y apagarlas es necesario dar "clic" en el switch
                        <mdb-icon fas icon="toggle-on"></mdb-icon>.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>


        <div class="col-md-4 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/5.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3">
                        <h5>5- DESPLEGAR SIMBOLOGÍA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify">
                        Para desplegar la simbología de alguna capa, es necesario dar "clic" en el ícono <mdb-icon fas
                            icon="plus-circle"></mdb-icon>, para ocultar de nuevo la simbología, es necesario presionar
                        <mdb-icon fas icon="minus-circle"></mdb-icon>
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>



        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/6.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3">
                        <h5>6- ZOOM EN EL MAPA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify"> Para hacer zoom dentro del mapa, puede realizarse por dos
                        métodos, el primero es usando el scroll de tu mouse <mdb-icon fas icon="mouse"></mdb-icon>. El
                        segundo método es utilizando los íconos <mdb-icon fas icon="plus-square"></mdb-icon> y <mdb-icon
                            fas icon="minus-square"></mdb-icon> que se encuentran en la esquina inferior derecha del
                        mapa.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>


        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/7.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3">
                        <h5>7- DESPLEGAR LOS DETALLES DEL PREDIO</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify">
                        Para desplegar los detalles de cualquier predio es necesario dar "clic" sobre el predio, el
                        sistema automáticamente desplegará un recuadro que contiene un poco de información del predio
                        como la clave catastral del mismo, dentro del recuadro se encuentra un botón que dice "Ver
                        detalle", al hacer "clic" el sistema desplegará más información sobre el predio.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>

        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/8.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3">
                        <h5>8- INFORMACIÓN DEL PREDIO Y FICHAS</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify">
                        Una vez desplegado el detalle del predio, la ventana mostrará a lado izquierdo la información
                        además de la posibilidad de Consultar giros Compatibles para tu predio, la ficha técnica y el
                        vínculo con las dependencias, solo es necesario dar "clic" en el botón sobre el reporte que
                        desees, el navegador descargará la ficha del predio (Es necesario que permitas que el navegador
                        abra otra pestaña con el documento).
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>


        <div class="col-md-12 mt-5 pb-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/9.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3">
                        <h5>9- HERRAMIENTA IDENTIFICA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify">
                        Para utilizar la herramienta "Identifica", es necesario activarla en el ícono <mdb-icon fas
                            icon="info-circle"></mdb-icon>, una vez activada prosigue a dar "clic"s dentro de cualquier
                        predio que quieras conocer más información. El sistema mostrará un recuadro con la información
                        específica del predio "Solo se muestra la información de las capas que han sido encendidas
                        previamente, si deseas ver información específica sobre una capa, es necesario encenderla".
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>


    </div>


    <hr>
    <div class="row my-4">
        <div class="col-md-6" id="mapa">
            <h4>
                ¿Listo? Dirigete al mapa dando "clic" en el siguiente botón.
            </h4>
        </div>
        <div class="col-md-6">
            <a href="https://tepatitlan.visorurbano.com/mapa" mdbBtn type="button" size='lg' color="dark-green"
                rounded="true" mdbWavesEffect>Ir
                al Mapa</a>
        </div>
    </div>
</div>