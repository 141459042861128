import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './components/about/about.component';
import { DictamentutorialComponent } from './components/dictamentutorial/dictamentutorial.component';
import { ErrComponent } from './components/err/err.component';
import { IndexComponent } from './components/index/index.component';
import { MaintotutorialsComponent } from './components/maintotutorials/maintotutorials.component';
import { NoficialmainComponent } from './components/noficialmain/noficialmain.component';
import { NoficialtutorialComponent } from './components/noficialtutorial/noficialtutorial.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { VideotutorialsComponent } from './components/videotutorials/videotutorials.component';


const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: "acerca",
    component: AboutComponent
  },
  {
    path: "tutorial",
    component: TutorialComponent
  },
  {
    path: "tutorialdictamen",
    component: DictamentutorialComponent
  },
  {
    path: "tutoriales",
    component: MaintotutorialsComponent
  },
  {
    path: "videotutoriales",
    component: VideotutorialsComponent
  },
  {
    path: "error404",
    component: ErrComponent
  },
  {
    path: "tutorialnumeroficial",
    component: NoficialmainComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
