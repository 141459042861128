
<div class="row fixed-top" style="background-color: white;">
    <p></p>
</div>

<mdb-navbar SideClass="navbar navbar-expand-lg navbar fixed-top mt-3 primary-color-dark navbar-dark  py-0 mt-0 "
    [containerInside]="false" style="background: #235aa2 !important;">
   
    <mdb-navbar-brand>
        <a class="navbar-brand " routerLink="/" class="mx-md-3 mx-auto ">
            <img src="assets/media/images/visor.png" style="max-width: 100px; background-color: white;">
        </a>
    </mdb-navbar-brand>

    <links class="ml-3 ">
        <ul class="navbar-nav mr-auto ">
            <li class="nav-item  pt-3 ">
                <a class="nav-link waves-light  text-right " routerLink="/" mdbWavesEffect>
                    <p>
                        Inicio
                    </p>
                </a>
            </li>
            <li class="nav-item  pt-3 ">
                <a class="nav-link waves-light  text-right " routerLink="/acerca" mdbWavesEffect>
                    <p>
                        Acerca
                    </p>
                </a>
            </li>
            <li class="nav-item pt-3 ">
                <a class="nav-link waves-light  text-right " routerLink="/tutoriales" mdbWavesEffect>
                    <p>
                        Tutoriales
                    </p>
                </a>
            </li>
        </ul>
        <a class="nav-link waves-light  text-right   " (click)="basicModal.show()" mdbWavesEffect>
            <p class="pt-3"><mdb-icon fas icon="desktop"></mdb-icon> Contacto</p>
        </a>

    </links>
</mdb-navbar>

<div class="col-4 col-md-2 fixed-top  justify-content-center mt-0 px-0" style="margin: auto; background: #235aa2;"  >
  
        <div class="col-12 px-0">
            <img src="assets/media/images/logo_oficial(1).png" class="img-fluid pl-3" alt="..." style="background: white;" />
        </div>
        <div class="col-12 px-0  text-center " >
           <h5 class=" my-auto font-weight-bold" style="color: white; ">VisorUrbano</h5>
        </div>
</div>

<div mdbModal #basicModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100 grey-text text-center" id="myModalLabel">Información de contacto</h4>
            </div>
            <div class="modal-body grey-text px-5">

                <p style="color: grey !important;">
                    <mdb-icon class="light-green-text" fas icon="phone-alt"></mdb-icon> 378-788-8700 ext. 8815 - 8819
                </p>


                <a href="tel:3787888772" >
                    <p style="color: grey !important;">
                        <mdb-icon fas icon="phone-alt" mdb-icon class="light-green-text"></mdb-icon> 378-788-8772
                    </p>
                </a>

                <a href="https://goo.gl/maps/1TZDFJXC6XZXmJsR9" target="_blank" >
                    <p style="color: grey !important;">
                        <mdb-icon fas icon="map-marker-alt" mdb-icon class="light-green-text"></mdb-icon> Morelos #320
                        Tepatitlán
                        de Morelos Jal.
                    </p>
                </a>


            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="dark-green" class="waves-light btn-sm" aria-label="Close"
                    (click)="basicModal.hide()" mdbWavesEffect>Cerrar</button>

            </div>