
<div class="container pt-5">

    <div class="row pt-5 d-flex ">
        <div class=" d-none d-md-block">
            <br><br><br><br><br>
        </div>
        <div class="row d-flex  justify-content-center">
            <div class="col-md-8">
                <img src="assets/media/images/tutorial.jpg" alt="thumbnail" class="img-thumbnail" class=" img-fluid z-depth-0 ">
            </div>
        </div>
    </div>



    <div class="row ">
        <div class="col-md-12 ">
            <h3 class="">TUTORIAL DICTAMEN DE USO DE SUELO</h3>
            <p class="grey-text ">Bienvenido al Tutorial de Visor Urbano, en este apartado te enseñaremos a utilizar la herramienta "Dictamen de Uso de Suelo".</p>
        </div>
    </div>



    <div class="row">

        <div class="col-md-12">
            <!-- card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card image-->
                    <mdb-card-img src="assets/media/images/1.jpg" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!-- Card content-->
                <mdb-card-body>
                    <!-- Title -->
                    <mdb-card-title class="mt-4 ">
                        <h5>1- ACCEDER AL MAPA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text"> En la vista principal de tepatitlan.visorurvano.com se encuentra un botón con el texto "Ir al mapa", debes de dar "clic" en el mismo.
                    </mdb-card-text>
                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>

        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/images/2.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>2- VISTA GENERAL DEL MAPA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text"> En la vista general del mapa, encontrarás capas cargadas por default y las herramientas disponibles dentro de Visor.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>

        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/6.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>3- ZOOM EN EL MAPA</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text"> Para hacer zoom dentro del mapa, puede realizarse por dos métodos, el primero es usando el scroll de tu mouse
                        <mdb-icon fas icon="mouse"></mdb-icon>. El segundo método es utilizando los iconos
                        <mdb-icon fas icon="plus-square"></mdb-icon> y
                        <mdb-icon fas icon="minus-square"></mdb-icon> que se encuentran en la esquina inferior derecha del mapa.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->

        </div>

        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/tutorial/7.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>4- DESPLEGAR LOS DETALLES DEL PREDIO</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text">
                        Para desplegar los detalles de cualquier predio es necesario dar "clic" sobre el predio, el sistema automáticamente desplegará un recuadro que contiene un poco de información del predio como la clave catastral del mismo, dentro del recuadro se encuentra
                        un botón que dice "Ver detalle", al hacer "clic" el sistema desplegará más información sobre el predio.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>


        <div class="col-md-12 mt-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/images/5.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>5- INFORMACIÓN DEL PREDIO Y FICHAS</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text">
                        Una vez desplegado el detalle del predio, la ventana mostrará a lado izquierdo la información además de la posibilidad de Consultar giros Compatibles para tu predio, la asignación de No. Oficial, el vínculo con las dependencias y el botón de dictamen
                        de uso de suelo, solo es necesario dar "clic" en el botón sobre el reporte que desees, el navegador descargará la ficha del predio (Es necesario que permitas que el navegador abra otra pestaña con el documento).
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>
        <div class="col-md-12 mt-5 pb-5">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/images/6.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>6- BOTON OBTEN TU DICTAMEN DE USO DE SUELO</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text">
                        Al dar "clic" en el botón obtén tu dictamen de uso del suelo, te enviara a una nueva página.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>

        <div class="col-md-12">
            <!-- Card -->
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/images/7.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>7- VAMOS A GENERAR TU DICTAMEN</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text">
                        Una vez en esta página, podrás ver al lado izquierdo el croquis y la información de tu predio. Genera el dictamen de uso de suelo para tu negocio dando "clic" en el botón que se encuentra al lado derecho, al presionar el botón te mostrará un aviso:
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>

        <div class="col-md-6 mt-5">
            <div class="card card-cascade wider">

                <!-- Card image -->
                <div class="view view-cascade overlay">
                    <img class="card-img-top" src="assets/media/images/8.png" alt="Card image cap">
                    <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!-- Card content -->
                <div class="card-body card-body-cascade text-center">

                    <!-- Title -->
                    <h4 class="card-title red-text"><strong>IMPORTANTE</strong></h4>
                    <!-- Text -->
                    <p class="card-text gray-text text-justify">Este aviso solo es para informarte que es un giro por dictamen, haga "clic" en Ok</p>
                </div>

            </div>
        </div>
        <div class="col-md-6 mt-5">
            <div class="card card-cascade wider">

                <!-- Card image -->
                <div class="view view-cascade overlay">
                    <img class="card-img-top" src="assets/media/images/9.png" alt="Card image cap">
                    <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!-- Card content -->
                <div class="card-body card-body-cascade text-center">

                    <!-- Title -->
                    <h5 class="card-title ">8- ESCRIBE TUS DATOS</h5>
                    <!-- Text -->
                    <p class="card-text gray-text text-justify">Escribe tus datos en esta parte te pediremos: tu nombre (como aparece en tu identificación oficial), correo electrónico, giro que deseas para tu dictamen y la cantidad de metros cuadrados que tendría tu negocio. </p>
                </div>
            </div>
        </div>
        <div class="col-md-6 mt-5">
            <div class="card card-cascade wider">

                <!-- Card image -->
                <div class="view view-cascade overlay">
                    <img class="card-img-top" src="assets/media/images/13.png" alt="Card image cap">
                    <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!-- Card content -->
                <div class="card-body card-body-cascade text-center">

                    <!-- Title -->
                    <h5 class="card-title ">9- SELECCIONA TU GIRO</h5>
                    <!-- Text -->
                    <p class="card-text gray-text text-justify">Despliega el listado de giros y selecciona el que necesitas. Escribe la superficie que pretendes utilizar y da "clic" en agregar. </p>
                </div>

            </div>
        </div>
        <div class="col-md-6 mt-5">
            <div class="card card-cascade wider">

                <!-- Card image -->
                <div class="view view-cascade overlay">
                    <img class="card-img-top" src="assets/media/images/14.png" alt="Card image cap">
                    <a href="#!">
                        <div class="mask rgba-white-slight"></div>
                    </a>
                </div>

                <!-- Card content -->
                <div class="card-body card-body-cascade text-center">

                    <!-- Title -->
                    <h5 class="card-title ">10- BOTÓN ENVIAR</h5>
                    <!-- Text -->
                    <p class="card-text gray-text text-justify">Ahora solo da "clic" en el botón enviar, Visor te enviara una solitud de confirmación a tu correo. </p>
                </div>
            </div>
        </div>

        <div class="col-md-12 mt-5">
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/images/11.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>11- Revisa tu Correo y confirma tu identidad</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text">
                        Al revisar tu correo (O revisa el spam), en la parte inferior da "clic" en el botón 'Confirma tu identidad', acto seguido se te enviará un mensaje de confirmación.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>
        <div class="col-md-12 mt-5">
            <mdb-card>
                <div class="view rgba-white-slight waves-light" mdbWavesEffect>
                    <!-- Card img -->
                    <mdb-card-img src="assets/media/images/12.png" alt="Card image cap"></mdb-card-img>
                    <a>
                        <div class="mask"></div>
                    </a>
                </div>
                <!--Card content-->
                <mdb-card-body>
                    <!--Title-->
                    <mdb-card-title class="pt-3 font-weight-bold">
                        <h5>!Felicidades!</h5>
                    </mdb-card-title>

                    <!--Text-->
                    <mdb-card-text class="text-justify gray-text">
                        Regresa a la bandeja de entrada de tu correo (O revisa el spam), encontrarás un correo con el archivo .PDF de tu dictamen.
                    </mdb-card-text>

                </mdb-card-body>
            </mdb-card>
            <!-- Card -->
        </div>
    </div>

    <hr>
    <div class="row my-5">
        <div class="col-md-6" id="mapa">
            <h4>
                ¿Listo? Dirigete al mapa dando "clic" en el siguiente botón.
            </h4>
        </div>
        <div class="col-md-6">
            <a href="https://tepatitlan.visorurbano.com/mapa" mdbBtn type="button" size='lg' color="dark-green" rounded="true" mdbWavesEffect>Ir
            al Mapa</a>
        </div>
    </div>
</div>
