<mdb-card style="background-image: url('assets/media/images/mapa3.png') ; background-position: center;"
  class="pt-3 rgba-black-slight">
  <div class="  text-center rgba-white-light mt-3 py-5 px-4 animated zoomInDown" style="color:  #235aa6;">
    <div class="py-5">
      <!-- Content -->
      <div class="row justify-content-md-center">
        <div class="col-md-4 pt-5">
          <img src="assets/media/images/marca.png" class="img-fluid" alt="Responsive image">
        </div>
      </div>
      <div class="row ">
        <div class="col-md-3 ">
          <img src="assets/media/images/premio.png" class="img-fluid" alt="Responsive image">
        </div>
        <div class="col-md-6">
          <h2 class="mt-3">¡Entra al mapa!, para consultar información sobre tu predio y todo Tepatitlán de Morelos,
            Jal.
          </h2>
          <p class="mb-4 pb-2 px-md-5 mx-md-5 mt-2">

          </p>
          <a href="https://tepatitlan.visorurbano.com/mapa" mdbBtn type="button" size='lg' rounded="true" mdbWavesEffect
            style="background-color: #235aa6 ; color: whitesmoke;">Ir
            al Mapa</a>
        </div>
      </div>



    </div>
  </div>
</mdb-card>


<div class="container">
  <div class="row  justify-content-md-center">
    <div class="col-12 my-5 text-center">
      <h1>Conoce lo nuevo</h1>
    </div>

    <div class="col-md-4 p-2 ">
      <div class="div shadow p-2" style="height: 420px;">
        <div class=" col-12  text-center contenedor-icono ">
          <i class=" green-text bi bi-map-fill icono"></i>
        </div>
        <div>
          <p class="h5 text-center">INFORME CEMENTERIOS</p>
          <div class="text-center " style="margin-top: 90px;">
            <a href="https://www.tepatitlan.gob.mx/IMPLAN/documentos/IMPLAN%20-%20Informe%20Cementerios.pdf"
              target="_blank" mdbBtn color="dark-green" mdbWavesEffect>Ir al Informe</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 p-2 ">
      <div class="div shadow p-2" style="height: 420px;">
        <div class=" col-12  text-center contenedor-icono ">
          <!-- <i class=" green-text bi bi-map-fill icono"></i> -->
          <img src="assets/media/icons/camera-drone.png" alt="" class=" mt-1" style="width: 200px;">
        </div>
        <div>
          <p class="h5 text-center mt-3">PLAN MUNICIPAL DE ORTOFOTOGRAFIAS AÉREAS 2024</p>
          <div class="text-center " style="margin-top: 75px;">
            <a href="https://www.tepatitlan.gob.mx/IMPLAN/documentos/Plan%20Municipal%20de%20Ortofotografías%20Aéreas%202024.pdf"
              target="_blank" mdbBtn color="dark-green" mdbWavesEffect>Ir al Informe</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 p-2 ">
      <div class="div shadow p-2" style="height: 420px;">
        <div class=" col-12  text-center contenedor-icono ">
          <!-- <i class=" green-text bi bi-map-fill icono"></i> -->
          <img src="assets/media/icons/central-park.png" alt="" class=" my-4" style="width: 150px;">
        </div>
        <div>
          <p class="h5 text-center mt-3">ARBOLADO URBANO</p>
          <div class="text-center " style="margin-top: 105px;">
            <a href="https://www.tepatitlan.gob.mx/IMPLAN/documentos/Arbolado%20Urbano%20IMPLAN%20Tepatitlan_.pdf"
              target="_blank" mdbBtn color="dark-green" mdbWavesEffect>Ir al Informe</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 p-2 ">
      <div class="div shadow p-2" style="height: 420px;">
        <div class=" col-12  text-center contenedor-icono ">
          <i class=" green-text bi bi-thermometer-sun icono"></i>
        </div>
        <div>
          <p class="h5 text-center">ANALISIS DE RIESGOS
            Y VULNERABILIDAD CLIMATICA</p>
          <div class="text-center " style="margin-top: 75px;">
            <a href="https://www.tepatitlan.gob.mx/IMPLAN/documentos/Tepatitlán_ARVC_Versión_Final.pdf" target="_blank"
              mdbBtn color="dark-green" mdbWavesEffect>Ir al Informe</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4 p-2 ">
      <div class="div shadow p-2" style="height: 420px;">
        <div class=" col-12  text-center contenedor-icono ">
          <i class=" green-text bi bi-globe-americas icono"></i>
        </div>
        <div>
          <p class="h5 text-center">ISLAS DE CALOR </p>
          <div class="text-center " style="margin-top: 75px;">
            <a href="https://tepatitlan.gob.mx/IMPLAN/documentos/Islas de Calor en Tepatitlán.pdf" target="_blank"
              mdbBtn color="dark-green" mdbWavesEffect>Ir al Informe</a>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>





<div class="container ">

  <div class="row justify-content-md-center py-5">

    <!-- <div class="col-md-6 col-12 ">
    <h2 class="text-center">Entra al mapa y obten la infomacion de tu predio</h2>
    <ul>
      <li>Conoce tu infomación Catastrál de tu predio.</li>
      <li> <span>¿Quieres poner un negocio? </span>  Conoce los Giros compatible para tu predio</li>
      <li>ewfwef</li>
    </ul>
  </div> 
   <div class="col-md-6 col-12">
    <img src="assets/media/images/test.jpg" alt="" class="img-fluid z-depth-0">
  </div> -->

    <div class="col-12 mb-3">
      <h2 class="h1  font-weight-bold text-cente">Con VisorUrbano te resolvemos tus dudas</h2>
    </div>




    <div class="col-md-3 mt-4 animated flipInX " data-wow-delay="2.3s">
      <!-- Card -->
      <mdb-card>
        <div class=" green-text text-center py-3" mdbWavesEffect>
          <!-- Card img -->
          <mdb-icon fas icon="home" size=7x></mdb-icon>
          <a>
            <div class="mask"></div>
          </a>
        </div>
        <!--Card content-->
        <mdb-card-body>
          <!--Title-->
          <mdb-card-title>
            <h4>¿Y tu predio?</h4>
          </mdb-card-title>

          <!--Text-->
          <mdb-card-text> Conoce rápidamente sin necesidad de ir a catastro las medidas de tu predio, adeudo, valores,
            entre otros datos de tu predio. <span class="font-weight-bolder"> Además, puedes descargar la Ficha Técnica.
            </span>
          </mdb-card-text>
          <div class="text-center">
            <!-- <a  href="https://tepatitlan.visorurbano.com/mapa" mdbBtn color="dark-green" class="" mdbWavesEffect>Entrar</a> -->
          </div>

        </mdb-card-body>
      </mdb-card>
      <!-- Card -->
    </div>


    <div class="col-md-3 mt-4  animated fadeInLeft">
      <!-- Card -->
      <mdb-card>
        <div class=" green-text text-center py-3" mdbWavesEffect>
          <!-- Card img -->
          <mdb-icon fas icon="briefcase" size="7x"></mdb-icon>
          <a>
            <div class="mask"></div>
          </a>
        </div>
        <!--Card content-->
        <mdb-card-body>
          <!--Title-->
          <mdb-card-title>
            <h4>¿Quieres poner un negocio?</h4>
          </mdb-card-title>

          <!--Text-->
          <mdb-card-text><span class="font-weight-bolder"> Conoce rápidamente que negocios son permitidos en tu
              predio</span> solo descargando la ficha de Giros Compatibles.
            Aprende a descargar tu Dictamen de Uso de suelo para negocios.
          </mdb-card-text>
          <div class="text-center">
            <a routerLink="/tutorialdictamen" mdbBtn color="dark-green" mdbWavesEffect>Tutorial</a>
          </div>

        </mdb-card-body>
      </mdb-card>
      <!-- Card -->
    </div>


    <div class="col-md-3 mt-4 animated fadeInLeft">
      <!-- Card -->
      <mdb-card>
        <div class=" green-text text-center py-3" mdbWavesEffect>
          <!-- Card img -->
          <mdb-icon far icon="file-alt" size="7x"></mdb-icon>
          <a>
            <div class="mask"></div>
          </a>
        </div>
        <!--Card content-->
        <mdb-card-body>
          <!--Title-->
          <mdb-card-title>
            <h4>¿Tendrá mi predio alguna licencia?</h4>
          </mdb-card-title>

          <!--Text-->
          <mdb-card-text> Descarga la ficha de Vínculos a Dependencias y entérate de las licencias que tiene o tuvo tu
            predio.
          </mdb-card-text>
          <div class="text-center">
            <!-- <a  href="https://tepatitlan.visorurbano.com/mapa" mdbBtn color="dark-green" mdbWavesEffect>Entrar</a> -->
          </div>

        </mdb-card-body>
      </mdb-card>
      <!-- Card -->
    </div>


    <div class="col-md-3 mt-4 animated fadeInLeft">
      <!-- Card -->
      <mdb-card>
        <div class=" green-text text-center py-3" mdbWavesEffect>
          <!-- Card img -->
          <mdb-icon fas icon="dollar-sign" size="7x"></mdb-icon>
          <a>
            <div class="mask"></div>
          </a>
        </div>
        <!--Card content-->
        <mdb-card-body>
          <!--Title-->
          <mdb-card-title>
            <h4>Conoce el valor de tu predio</h4>
          </mdb-card-title>

          <!--Text-->
          <mdb-card-text> Entra al grupo de capas llamada Predial y activa la capa de valores 2021, entérate el costo
            por metro cuadrado de tu predio.
          </mdb-card-text>
          <div class="text-center">
            <!-- <a  href="https://tepatitlan.visorurbano.com/mapa" mdbBtn color="dark-green" mdbWavesEffect>Entrar</a> -->
          </div>

        </mdb-card-body>
      </mdb-card>
      <!-- Card -->
    </div>


  </div>

  <hr>
  <div class="row my-5">

    <div class="row">
      <div class="col-md-6">
        <div class="row justify-content-md-center">
          <div class="col-md-8">
            <img src="assets/media/images/think.jpg" class="img-fluid " alt="..." />
          </div>
        </div>

      </div>
      <div class="col-md-6">
        <h2 class="r">¿Sabías qué?</h2>

        <p class="px-md-5 px-1 mb-5  lead grey-text text-justify  ">
          Visor Urbano no genera información solo muestra la proporcionada por:
        </p>
        <ul>
          <li>
            <p class="grey-text text-left">Catastro</p>
          </li>
          <li>
            <p class="grey-text text-left">Padrón y Licencias </p>
          </li>
          <li>
            <p class="grey-text text-left">ASTEPA</p>
          </li>
          <li>
            <p class="grey-text text-left">Ordenamiento Territorial (Planeación y Desarrollo Urbano)</p>
          </li>
        </ul>
        <p class="grey-text text-left font-weight-bold">
          Cualquier error en los datos es responsabilidad directa de la jefatura que proporcionó la información.
        </p>
      </div>
    </div>

  </div>


  <div class="row ">
    <div class="col-md-6">
      <h2 class="r">¡VisorUrbano 3D!</h2>

      <p class="px-md-5 px-1 mb-5  lead grey-text text-justify  ">
        Visor Urbano tiene ahora su primer modelo 3D, adentrate a descubrir el modelo en tercera dimensión del Mercado
        Centenario, descarga las fichas y entérate de la información de los locales.
      </p>
      <div class="text-right mr-5">
        <button type="button" mdbBtn color="success" mdbWavesEffect>
          <a href="https://tepatitlan.visorurbano.com/mercado/Apps/mercado.html" class="text-white">
            modelo 3D
          </a>
        </button>
      </div>


    </div>

    <div class="col-md-6">
      <img src="assets/media/images/mercado.jpg" class="img-fluid z-depth-2" alt="..." />
    </div>
  </div>


  <div class="row pt-5 my-5">

    <div class="row">
      <div class="col-md-8">
        <div class="row justify-content-md-center">
          <div class="col-md-8">
            <img src="assets/media/images/youtube.png" class="img-fluid " alt="..." />
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <h2 class="r">Tutoriales de VisorUrbano ahora en Youtube</h2>

        <p class="px-md-5 px-1 mb-5  lead grey-text text-justify  ">
          Aprende de manera más fácil a utilizar VisorUrbano ahora con videos.
          Ve a la sección de tutoriales o da clic en el siguiente botón </p>

        <button type="button" mdbBtn color="success" mdbWavesEffect>
          <a routerLink="/videotutoriales" class="text-white">
            video tutoriales
          </a>
        </button>
        <!-- <ul>
            <li>
              <p class="grey-text text-left">Catastro</p>
            </li>
            <li>
              <p class="grey-text text-left">Padrón y Licencias </p>
            </li>
            <li>
              <p class="grey-text text-left">ASTEPA</p>
            </li>
            <li>
              <p class="grey-text text-left">Ordenamiento Territorial (Planeación y Desarrollo Urbano)</p>
            </li>
          </ul> -->

      </div>
    </div>

  </div>



  <hr>

  <div class="row justify-content-center">
    <div class="col-md-10 view overlay zoom my-md-3 my-5">
      <img src="assets/media/images/ortofoto.jpg" class="img-fluid zoom" alt="Responsive image">
      <a href="https://tepatitlan.visorurbano.com/mapa">
        <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
      </a>
    </div>
  </div>

  <hr>
  <section class="py-5  animated fadeIn">
    <!--Section heading-->
    <h2 class="r">¡ENTÉRATE DE LO QUE ES VISOR!</h2>
    <!--Section description-->
    <p class="px-md-5 px-1 mb-5 pb-3 lead grey-text text-justify  ">
      Visor Urbano es una plataforma digital de gestión urbana que presenta las normas y planes de desarrollo urbano de
      Tepatitlán. Permite consultar qué tipo de negocios y construcción se pueden realizar en el predio seleccionado.
      Cuenta con apartados de capas temáticas de información georreferenciada sobre el territorio municipal. Permite
      tramitar en línea un número oficial y un dictamen de uso de suelo para negocio. Toda la información presentada en
      Visor Urbano es de consulta libre.
    </p>

    <!--Grid row-->
    <div class="row pt-2">

      <!--Grid column-->
      <div class="col-lg-5 text-center text-lg-left  justify-content-center">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <img src="assets/media/images/test.jpg" alt="" class="img-fluid z-depth-0">
          </div>
        </div>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-7">

        <!--Grid row-->
        <div class="row pb-3">
          <div class="col-2 col-md-1">



            <mdb-icon far icon="check-circle" size="lg" class="text-success"></mdb-icon>
          </div>
          <div class="col-10">
            <h5 class="font-weight-bold text-left mb-3 dark-grey-text">¿Qué es Visor Urbano?</h5>

            <ul>
              <li>
                <p class="grey-text text-left">Política pública que vincula al Gobierno y los ciudadanos. </p>
              </li>
              <li>
                <p class="grey-text text-left">Funge como un instrumento que garantiza la transparencia</p>
              </li>
              <li>
                <p class="grey-text text-left">Plataforma digital de gestión del territorio. </p>
              </li>
              <li>
                <p class="grey-text text-left">Trámites en linea. </p>
              </li>

            </ul>


          </div>
        </div>
        <!--Grid row-->

        <!--Grid row-->
        <div class="row pb-3">
          <div class="col-2 col-md-1">
            <mdb-icon far icon="check-circle" size="lg" class="text-success"></mdb-icon>
          </div>
          <div class="col-10">
            <h5 class="font-weight-bold text-left mb-3 dark-grey-text">¿Qué objetivos tiene Visor Urbano?</h5>

            <ul>
              <li>
                <p class="grey-text text-left">Conocer los planes de desarrollo de la ciudad. </p>
              </li>
              <li>
                <p class="grey-text text-left">Combatir la corrupción en los permisos del desarrollo urbano.</p>
              </li>
              <li>
                <p class="grey-text text-left">Evitar la discrecionalidad en la emisión de licencias para construcción y
                  para negocios. </p>
              </li>


            </ul>

            <a routerLink="/acerca">
              <p>Conoce más de Visor haciendo "clic" aquí...</p>
            </a>
          </div>
        </div>
        <!--Grid row-->


      </div>
      <!--Grid column-->

    </div>
    <!--Grid row-->

  </section>



  <hr>
  <app-noficialtutorial></app-noficialtutorial>
  <app-contactnoficiales> </app-contactnoficiales>

  <hr>

  <div class="row py-5 justify-content-md-center animated flipInX ">
    <div class="col-md-4 text-center red-text">
      <h2 class="font-weight-bold">¡Alto! </h2>
      <br>
      <mdb-icon class="danger" far icon="hand-paper" size="7x"></mdb-icon>
      <br>

    </div>
    <div class="col-md-6 text-left">
      <h2> Aprende cómo utilizar Visor</h2>
      <p class="grey-text"> Entra al tutorial haciendo "clic" en el siguiente botón y aprende como utilizar las
        herramientas que Visor tiene para ti.</p>
      <a routerLink="/tutorial" mdbBtn color="dark-green" mdbWavesEffect>ir al tutorial</a>
    </div>
  </div>

</div>