<style>
  footer a {
    color: #fff;
    text-decoration: none;
    border-bottom: 0;
  }

  footer p {
    color: #fff;
    text-decoration: none;
    border-bottom: 0;
  }

  .footer-background {
    background-color: gray;
  }

  .footer-background .creditos-footer {
    background-color: #235aa6;
    font-size: 0.85em;
    padding: 5px 0px;
  }

  @media (max-width: 992px) {
    .footer-background .creditos-footer {
      font-size: 0.75em;
    }
  }

  .imagen-gobierno-footer {
    margin-top: -10px;
  }

  .footer-enlaces {
    font-size: 0.85em;
    text-align: left;
  }

  .footer-enlaces p {
    margin-bottom: 1px;
  }

  .informacion-gobierno {
    font-size: 0.85em;
    margin-bottom: 1px;
  }

  @media (max-width: 992px) {
    .informacion-gobierno:nth-child(5) {
      margin-bottom: 15px;
    }
  }

  .aviso-privacidad {
    font-size: 0.75em;
  }

  @media (max-width: 992px) {
    .aviso-privacidad {
      margin-top: 15px;
    }
  }

  .aviso-privacidad h6 {
    font-size: 1em;
  }


  /* ============================================ENLACES QUE TE PUEDEN INTERESAR============================================= */

  .hr-title {
    margin-left: 24%;
    margin-right: 15%;
    width: 60%;
    margin-top: 0;
    margin-bottom: 0;
    height: 2px !important;
    background-color: #fff !important;
    opacity: 1;
  }

  .fw-bold {
    font-size: 18px;
    font-weight: 500;
    color: #fff !important;
  }
</style>

<div class="container-fluid px-0 footer-background">
  <footer class="text-center text-lg-start text-white pt-1">
    <!-- Section: Links  -->
    <section>
      <div class="container text-center text-md-start mt-0">
        <!-- Grid row -->
        <div class="row mt-1">
          <div class="col-md-3 col-lg-4 col-xl-3 mb-1">
            <a href="https://www.tepatitlan.gob.mx"><img src="assets/media/images/logoblanco.png"
                class="imagen-gobierno-footer img-fluid w-100" alt="Tepa"></a>
            <p class="text-center informacion-gobierno">Gobierno Municipal de Tepatitlán 2021-2024</p>
            <p class="text-center informacion-gobierno">Hidalgo #45, Col. Centro, Jal. C.P. 47600</p>
            <p class="text-center informacion-gobierno">Tepatitlán de Morelos</p>
            <p class="text-center informacion-gobierno">Tel: <a class="text-white"
                href="tel:3787888700">378-788-8700</a></p>
          </div>
          <div class="col-md-2 col-6 col-lg-2 col-xl-2 mx-auto mb-0 footer-enlaces">
            <h5 class="text-uppercase fw-bold mb-0">Enlaces</h5>
            <hr class="hr-title" />
            <p>
              <a href="https://www.tepatitlan.gob.mx/transparencia1821" class="text-white">Transparencia</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/indice" class="text-white">Índice</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/comunicacion" class="text-white">Comunicación</a>
            </p>
            <p>
              <a target="_blank" href="https://tepatitlan.visorurbano.com/#/" class="text-white">Visor Urbano</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/gobierno" class="text-white">Gobierno</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/catastro" class="text-white">Catastro</a>
            </p>
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-md-3 col-6 col-lg-2 col-xl-2 mx-auto mb-0 footer-enlaces">
            <!-- Links -->
            <h5 class="text-uppercase fw-bold mb-0">CIUDADANOS</h5>
            <hr class="hr-title" />
            <p>
              <a href="https://www.tepatitlan.gob.mx/ciudadanos/tramites/" class="text-white">Trámites y Servicios</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/catastro" class="text-white">Pago de predial</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/astepa" class="text-white">Pago de agua potable</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/dif" class="text-white">Dif</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/instituto-mujer" class="text-white">Instituto de la Mujer</a>
            </p>
            <p>
              <a href="https://www.tepatitlan.gob.mx/intej" class="text-white">Instituto de la Juventud</a>
            </p>
          </div>
          <!-- Grid column -->


        </div>
        <!-- Grid row -->
        <div class="row">
          <div class="col-12 text-center aviso-privacidad mb-3">
            <h5 class="text-white">Aviso de privacidad</h5>
            <p>
              <a href="https://www.tepatitlan.gob.mx/transparencia/avisos_privacidad/AVISO DE PRIVACIDAD INTEGRAL.pdf" class="mx-2 text-white"><i
                  class="fas fa-file-pdf mx-2"></i>Integral</a>
              <a href="https://www.tepatitlan.gob.mx/transparencia/avisos_privacidad/AVISO DE PRIVACIDAD SIMPLIFICADO.pdf" class="mx-2 text-white"><i
                  class="fas fa-file-pdf mx-2"></i>Simplicado</a>
              <a href="https://www.tepatitlan.gob.mx/transparencia/avisos_privacidad/AVISO DE PRIVACIDAD CORTO.docx" class="mx-2 text-white"><i
                  class="fas fa-file-pdf mx-2"></i>Corto</a>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="text-center creditos-footer">
      V9 : : 2021-2024 - Desarrollado por la
      <a class="text-white" href="#">Jefatura de Informática</a>
    </div>
    <!-- Copyright -->
  </footer>
</div>