<div class="container pt-5 pb-3">
    <div class="row pt-5">
       
        <div class="row d-flex justify-content-center">

            <div class="col-md-9 mt-5 pt-5">
                <img src="assets/media/images/marca.png" alt="" class="img-fluid ">
            </div>

        </div>

        <div class="col-md-12">
            <br>
            <h2>Acerca</h2>
            <p class=" grey-text text-justify"> El Gobierno Municipal de Tepatitlán buscando trascender en temas de
                transparencia y rendición de cuentas, además de atraer progreso e inversión, apuesta por una Plataforma
                que gestiona el desarrollo ordenado del territorio a través del uso de la tecnología digital, de manera
                moderna y ágil, en ese contexto Tepatitlán inició un proceso de transformación digital, para tener una
                ciudad compacta, cercana, conectada y equitativa para toda la ciudadanía.

                <span><br>
                    <br></span> VISOR URBANO opera a través de un mapa digital que contiene 14 módulos de información,
                en un total de 48 capas temáticas resaltando por su interés general los temas de predial, patrimonio y
                certidumbre catastral. El usuario interactúa con las capas, a través de dispositivos con acceso a
                internet.

                <span><br>
                    <br></span> VISOR Tepatitlán, es el único que incorpora la información referente al Sistema Operado
                de Agua Potable, de los 3 Visores a Nivel Nacional, la aportación más reciente es el apartado de
                Material Docente, que cuenta con capas de información útil pensada para alumnos y profesores.
            </p>
        </div>
        <div class="row pt-2 justify-content-center">
            <div class="col-md-3 col-4 mb-md-2 mb-4">
                <div class="row  justify-content-center">
                    <div class="col-sm-5">
                        <img src="assets/media/icons/crosshair.png" alt="" class="img-fluid ">
                    </div>
                </div>

            </div>
            <div class="col-md-9">
                <h4>¿Qué es lo que se pretende lograr?</h4>
                <p class=" grey-text text-justify">Lograr una ciudad organizada, promoviendo el acceso igualitario a la
                    información del Desarrollo Urbano, generando procesos digitales para las licencias de construcción y
                    negocios, combatiendo la corrupción y generando procesos transparentes por medio del uso de
                    tecnología, evitando la discrecionalidad e involucrando a la ciudadanía en la vigilancia e
                    inspección del desarrollo de la ciudad.</p>
            </div>
        </div>

        <div class="row pt-2  justify-content-center">

            <div class="col-md-3 col-4 mb-md-2 mb-4">
                <div class="row  d-flex justify-content-center">
                    <div class="col-sm-5">
                        <img src="assets/media/icons/love.png" alt="" class="img-fluid ">
                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <h4>¿Cómo ayuda a la población esta acción?</h4>
                <p class=" grey-text text-justify">Como una política pública que vincula al Gobierno y los ciudadanos de
                    manera eficiente, propiciando ventajas como el acceso a la información rápida y oportuna en línea y
                    descargable a nivel municipal, referente a temas como el uso de suelo, desarrollo urbano,
                    características de los predios, actividades permitidas, información básica sobre empresas,
                    características del territorio, permisos de negocios y de construcción georreferenciados, todo desde
                    un dispositivo Tablet, móvil o PC.</p>
            </div>
        </div>

        <div class="row pt-2 justify-content-center">

            <div class="col-md-3 col-4 mb-md-2 mb-4">
                <div class="row  d-flex justify-content-center">
                    <div class="col-sm-5 ">

                        <img src="assets/media/icons/check-mark.png" alt="" class="img-fluid ">


                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <h4>¿Cuáles son los resultados más importantes que se han obtenido con la implementación de la acción?
                </h4>
                <ul class="grey-text">
                    <li>Tepatitlán, logra ser el tercer municipio a nivel nacional y el segundo del estado de Jalisco en
                        implementar la Plataforma digital VISOR URBANO, siendo ésta una de las Plataformas de Gobierno
                        abierto más importantes a nivel nacional e internacional.</li>
                    <li>Tepatitlán ha logrado durante el tiempo de implementación de VISOR URBANO, el incremento en los
                        ingresos al tener una recaudación eficiente, contar con instrumentos de planeación urbana
                        digitalizada de libre acceso, e información catastral de libre consulta.</li>
                    <li>Así como se ha logrado impulsar la mejora regulatoria, adentrando al municipio en los temas de
                        Gobierno Abierto Digital y Tramitología en línea.</li>
                    <li>Se logró la publicación de la Información Catastral a escala municipal en tiempo real y de
                        fichas técnicas, con información catastral de cada predio.</li>
                </ul>
            </div>
        </div>

    </div>
    <hr>
    <div class="row my-4">
        <div class="col-md-6" id="mapa">
            <h4>
                ¿Listo? Dirigete al mapa dando clic en el siguiente botón.
            </h4>
        </div>
        <div class="col-md-6">
            <a href="https://tepatitlan.visorurbano.com/mapa" mdbBtn type="button" size='lg' color="dark-green"
                rounded="true" mdbWavesEffect>Ir
                al Mapa</a>
        </div>
    </div>
</div>